const _ = require('lodash');

let theBus;
let _raf;

const jobs = [];
const budget = 1000 / 60;

const getCurrentJob = function getCurrentJob() {
    return jobs[0];
};

const removeJob = function removeJob(job) {
    // console.debug('%cremoveJob(%o)', 'color: steelblue;', job);
    jobs.splice(jobs.indexOf(job), 1);
};

const gotWork = function gotWork() {
    return (jobs.length > 0);
};

const tick = function tick() {
    let timeSpent;
    let currentJob;
    let jobFinished;
    let start;

    _raf = undefined;
    timeSpent = 0;

    while (timeSpent < budget && gotWork()) {
        currentJob = getCurrentJob();
        jobFinished = false;
        while (!jobFinished && timeSpent < budget) {
            start = new Date();
            currentJob.state = currentJob.handler(currentJob.state);
            timeSpent += (new Date() - start);
            jobFinished = currentJob.state.finished;
        }
        if (jobFinished) {
            removeJob(currentJob);
            // } else {
            //     console.debug('%cjob not done in one tick', 'color: steelblue;');
        }
    }

    // schedule next tick
    if (gotWork()) {
        _raf = window.requestAnimationFrame(tick);
        // } else {
        //     console.debug('%csleeping', 'color: steelblue;');
    }
};

const addJob = function addJob(job) {
    _.defaults(job, {
        state: {},
        priority: 100
    });
    // console.debug('%caddJob(%o)', 'color: steelblue;', job);
    jobs.push(job);
    // jobs = _.sortBy(jobs, 'priority').reverse();
    if (!_raf) {
        _raf = window.requestAnimationFrame(tick);
    }
};

// first tick
tick();

/**
 * RafSlicer will execute work with an animation frame, and will schedule next work
 * for the next tick if it takes too long.
 *
 * @usage
 * theBus.trigger("rafSlicer:add", {
     *  state: {},
     *  handler: function (state) {
     *      state.count = state.count || 0;
     *      state.count += 1;
     *      state.finished = (state.count > 200000);
     *      return state;
     *   }
     *});
 */
module.exports = {
    on(bus) {
        this.off();
        theBus = bus;
        theBus.on('rafSlicer:add', addJob);
    },

    off() {
        if (theBus) {
            theBus.off('rafSlicer:add');
            theBus = undefined;
        }
    }
};
