const Router = require('core/main/router');

/**
 * @typedef {Object} Core.Main.RouterConfig
 * @property {CustomRouters.LimecraftRouter} type - The type of router
 * @property {String} prefix - Prefix prepended to all the subrouters's routes
 * @property {String} inject - Name of the method of the parent router to use for creating the injection for the subrouter
 * @property {String} name - Name of the subrouter. This will be used as the variable name on the parent router instance.
 * @property {Array.<Core.Main.RouterConfig>} subrouters - Configuration of this router's subrouters
 * @property {Array.<Core.Main.AppConfig>} apps - Configuration of this router's apps
 */

/**
 * @typedef {Object} Core.Main.AppConfig
 * @property {String} name - The name of the app
 * @property {String} [loadScript] - Path of the load script for this app. Alternatively use routerPath.
 * @property {String} [routerPath] - Path of the router for the app. Only used when loadScript is not defined.
 * @property {String} baseRoute - Prefix to use for all this app's routes
 * @property {String} [registerScript] - A script that is run when registering the application
 * @property {String} [unregisterScript] - A script that is run when unregistering the application
 * @property {Boolean} setupListenerRoute - Should a placeholder route be created for catching navigations to an app route before it is loaded?
 * @property {String} inject - Which method to call on the parent to create injection
 */

/**
 * This is the router config of the web application. Note that
 * for Artlantic, the Flow app has its own router config
 * defined in another file.
 *
 * @name routerConfig
 * @memberOf Core.Main
 * @type Core.Main.RouterConfig
 * @instance
 */
const routerConfig = {
    type: Router,
    subrouters: [],
    apps: ['Flow', 'Authentication', 'ShareViewer', 'Uploader']
};
module.exports = routerConfig;
