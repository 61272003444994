const config = require('configuration');
const Lime = require('lime-js');
const StyleLoaderMixin = require('style-loader-mixin-js');
const CustomCssSheet = require('toolbelt-js/src/custom-css-sheet');
const Color = require('toolbelt-js/src/color');
const tplMain = require('./main.haml.jst');

/**
 * @constructor MainView
 * @memberOf Core.Main
 * @extends Lime.View
 *
 * @param {Object} [options]
 *
 * @classdesc This is the main view. It is supposed to remain on
 * screen for the entire lifecycle of the web application. Use the
 * setContent method for rendering something in the content area
 * of this view.
 */
const MainView = Lime.View
    .extend(StyleLoaderMixin)
    .extend(
        /** @lends Core.Main.MainView.prototype */
        {
            el: 'body .wrapper',
            styles: ['assets/css/overrides.css'],
            regions: {
                content: '#content',
                overlayContent: '#overlay-content',
            },
            template: tplMain,

            onClose(...args) {
                Lime.$(window)
                    .off('.lc.mainview');
                if (this.cssSheet) {
                    this.cssSheet.close();
                    this.cssSheet = null;
                }
                this._super('onClose', args);
            },

            render(...args) {
                Lime._.bindAll(this, 'showFreshchat');
                this._super('render', args);

                Lime.$(window.document)
                    .on('click.lc.mainview', '.IntercomActivator,.SupportActivator', this.showFreshchat);

                return this.$el;
            },

            afterRender(...args) {
                this._super('afterRender', args);

                // add a data-custom-branding attribute to body
                const $body = Lime.$('body');
                $body.attr('data-custom-branding', 'env');
                if ($body.attr('data-color-mode') === undefined) {
                    $body.attr('data-color-mode', '');
                }
                if ($body.attr('data-layout-mode') === undefined) {
                    $body.attr('data-layout-mode', '');
                }
                this.cssSheet = new CustomCssSheet({
                    sheetName: 'main-view-sheet'
                });
                this.configToCss();
            },

            /**
             * Render the primaryBrandColor taken from configuration.json. Does nothing if this is not set.
             */
            configToCss() {
                let ruleIndex = 0;

                const cssVarScope = 'body[data-custom-branding="env"], body[data-custom-branding="env"] .new-lc-colors';

                // primaryBrandColor: a hex color which can be used to override primary brand color
                // e.g. { primaryBrandColor: "#1e77bc" }
                const primaryBrandColor = Lime._.get(config, 'primaryBrandColor');
                if (primaryBrandColor && primaryBrandColor !== 'NONE') {
                    const rgb = Color.hexToRgb(primaryBrandColor);
                    if (rgb) {
                        const hsl = Color.rgbToHsl(rgb.r, rgb.g, rgb.b);
                        if (hsl) {
                            this.cssSheet.addCSSRule(
                                cssVarScope,
                                `
                                --lc-color-primary-h: ${Math.round(hsl.h)};
                                --lc-color-primary-s: ${Math.round(hsl.s)}%;
                                --lc-color-primary-l: ${Math.round(hsl.l)}%;
                                `,
                                ruleIndex
                            );
                            ruleIndex += 1;
                        }
                    }
                }

                // customCssVars: an object which can be used to override css vars
                // e.g. { customCssVars: { "--lc-color-selection-h": "300" } }
                const customCssVars = Lime._.get(config, 'customCssVars', {});
                let cssVarRule = '';
                Lime._.each(customCssVars, (value, variable) => {
                    cssVarRule = `${cssVarRule} ${variable}: ${value};`;
                });
                if (cssVarRule !== '') {
                    this.cssSheet.addCSSRule(
                        cssVarScope,
                        cssVarRule,
                        ruleIndex
                    );
                    ruleIndex += 1;
                }
            },

            /**
             * Shorthand for setting the content region's content
             *
             * @param subView
             */
            setContent(subView) {
                // clear overlay content first
                this.getRegion('content')
                    .setContent(subView);
                if (Lime._.isUndefined(this._firstRenderTime)) {
                    this._firstRenderTime = new Date().getTime();
                    this.model.get('bus')
                        .trigger('trace:time', 'firstSetContent');
                }
            },

            /**
             * Displays the Freshchat dialog. If anything goes wrong (no Freshchat widget), it suggests sending a mail to support.
             * @param {*} e The click event
             */
            showFreshchat(e) {
                if (window.fcWidget && window.fcWidget.isInitialized()) {
                    e.preventDefault();
                    window.fcWidget.show();
                    window.fcWidget.open();
                    return;
                }

                const $target = Lime.$(e.target);
                if ($target.prop('tagName') === 'A' || $target.parents('A').length) {
                    return; // activated by clicking link, link should contain fallback to support mail, so just follow that
                }

                // fallback: show message with mail link
                if (Lime.supportMailLink) {
                    // strip out the 'activator' class to avoid loop
                    const link = Lime.supportMailLink.replace('IntercomActivator', '').replace('SupportActivator', '');
                    Lime.Alerter.showInfoMessage(
                        `Please send us a mail at ${link} and tell us how we can help you.`,
                        -1,
                    );
                }
            },
        },
    );

module.exports = MainView;
