const _ = require('lodash');
const routeMapping = require('./route-mapping');
const validReturnRoutes = require('./valid-return-routes');

// the default route to fall back to
const DEFAULT_ROUTE = '';

// these routes are valid routes for application entry
//  (refreshing to this page)
const entryRouteWhitelist = validReturnRoutes.concat([
    /^auth\/.*$/g,
    /^productions\/\d+\/team\/.*$/g,

    // account setup stuff, ogone feedback etc.
    /^users\/\d+\/accounts\/.*/g,

    /^subscriptionManager\/new\/account\/.*\/plan\/.*/g,
]);

// these routes are valid routes to return to (going
// to last saved route the user was active on)
const returnRouteWhitelist = validReturnRoutes.concat([]);

module.exports = function entryRouteRedirect(theRoute, theOptions) {
    const options = theOptions || {};
    let route = theRoute;
    _.defaults(options, { isEntryRoute: true });

    // check if route has to be mapped to other route
    // (used for backwards compatibility)
    _.each(routeMapping, (map) => {
        if (route.match(map.from)) {
            route = map.to.apply(null, map.from.exec(route));
        }
    });

    // check if the route is something mentioned in valid return routes
    // (used to prevent going to routes like delete/andThen/ etc.)
    const whiteList = options.isEntryRoute ? entryRouteWhitelist : returnRouteWhitelist;
    const validRoute = _.find(whiteList, regexp => route.match(regexp));

    if (!validRoute) {
        route = DEFAULT_ROUTE;
    }

    return route;
};
