/**
 *  Backwards compatibility: rewrite some old routes
 */

module.exports = [
    // Aug 2023: temp delivery workspace routes used during development
    {
        from: /productions\/accounts$/g,
        to() {
            return 'accounts';
        }
    },
    {
        from: /productions\/accounts\/(\d+)\/production-workspaces\/?$/g,
        to(route, accountId) {
            return `accounts/${accountId}/ws/production`;
        }
    },
    {
        from: /productions\/accounts\/(\d+)\/delivery-workspaces\/?$/g,
        to(route, accountId) {
            return `accounts/${accountId}/ws/delivery`;
        }
    },

    // Aug 2023: production router > production-settings router redirects were moved here
    {
        from: /productions\/(\d+)\/edit$/g,
        to(route, productionId) {
            return `productions/${productionId}/settings/general`;
        }
    },
    {
        from: /productions\/(\d+)\/edit\/(.+)/g,
        to(route, productionId, page) {
            return `productions/${productionId}/settings/${page}`;
        }
    },
    {
        from: /productions\/(\d+)\/admin$/g,
        to(route, productionId) {
            return `productions/${productionId}/settings/admin`;
        }
    },
    {
        from: /productions\/(\d+)\/admin-tools$/g,
        to(route, productionId) {
            return `productions/${productionId}/settings/admin-tools`;
        }
    },
    {
        from: /productions\/(\d+)\/workflows$/g,
        to(route, productionId) {
            return `productions/${productionId}/settings/workflows`;
        }
    },
    {
        from: /productions\/(\d+)\/workflows\/([^/]+)/g,
        to(route, productionId, optionString) {
            const optsPart = optionString ? `/${optionString}` : '';
            return `productions/${productionId}/settings/workflows${optsPart}`;
        }
    },
    {
        from: /productions\/(\d+)\/custom-status$/g,
        to(route, productionId) {
            return `productions/${productionId}/settings/custom-status`;
        }
    },
    {
        from: /productions\/(\d+)\/custom-status\/(.+)/g,
        to(route, productionId, optionString) {
            const optsPart = optionString ? `/${optionString}` : '';
            return `productions/${productionId}/settings/custom-status${optsPart}`;
        }
    },
    {
        from: /productions\/(\d+)\/mais$/g,
        to(route, productionId) {
            return `productions/${productionId}/settings/mais`;
        }
    },
    {
        from: /productions\/(\d+)\/mais\/([^/]+)/g,
        to(route, productionId, optionString) {
            const optsPart = optionString ? `/${optionString}` : '';
            return `productions/${productionId}/settings/mais${optsPart}`;
        }
    },
    {
        from: /productions\/(\d+)\/file-overview$/g,
        to(route, productionId) {
            return `productions/${productionId}/settings/file-overview`;
        }
    },
    {
        from: /productions\/(\d+)\/file-overview\/([^/]+)/g,
        to(route, productionId, optionString) {
            const optsPart = optionString ? `/${optionString}` : '';
            return `productions/${productionId}/settings/file-overview${optsPart}`;
        }
    },
    {
        from: /productions\/(\d+)\/share$/g,
        to(route, productionId) {
            return `productions/${productionId}/settings/share`;
        }
    },
    {
        from: /productions\/(\d+)\/share\/([^/]+)/g,
        to(route, productionId, optionString) {
            const optsPart = optionString ? `/${optionString}` : '';
            return `productions/${productionId}/settings/share${optsPart}`;
        }
    },
    {
        from: /productions\/(\d+)\/team\/overview$/g,
        to(route, productionId) {
            return `productions/${productionId}/settings/team`;
        }
    },
    {
        from: /productions\/(\d+)\/team$/g,
        to(route, productionId) {
            return `productions/${productionId}/settings/team`;
        }
    },

    // May 2021: new handle-invite route
    {
        from: /invite-accept\/accept-invite-ne\/(\d+)\/(\d+)\/([^/]+)\/([^/]+)\/?/g,
        to(route, productionId, userId, userEmail, invitationToken) {
            return `auth/handle-invite/production/${productionId}/invitation/${invitationToken}`;
        }
    },
    {
        from: /invite-accept\/invite-accepted\/(\d+)\/(\d+)\/([^/]+)\/([^/]+)\/?/g,
        to(route, productionId, userId, userEmail, invitationToken) {
            return `auth/handle-invite/production/${productionId}/invitation/${invitationToken}`;
        }
    },
    {
        from: /invite-accept\/account-accept-invite-ne\/(\d+)\/(\d+)\/([^/]+)\/([^/]+)\/?/g,
        to(route, accountId, userId, userEmail, invitationToken) {
            return `auth/handle-invite/account/${accountId}/invitation/${invitationToken}`;
        }
    },
    {
        from: /invite-accept\/account-invite-accepted\/(\d+)\/(\d+)\/([^/]+)\/([^/]+)\/?/g,
        to(route, accountId, userId, userEmail, invitationToken) {
            return `auth/handle-invite/account/${accountId}/invitation/${invitationToken}`;
        }
    },
    {
        from: /invite-accept\/(accept-invite-error\/.*)?/g,
        to(route, routePart) {
            return `auth/legacy-invite-error/${routePart}`;
        }
    },
    {
        from: /invite-accept\/(account-accept-invite-error\/.*)?/g,
        to(route, routePart) {
            return `auth/legacy-invite-error/${routePart}`;
        }
    },

    // Ancient invite routes under team
    {
        from: /^productions\/\d+\/team\/accept-invite-ne\/(\d+)\/(\d+)\/([^/]+)\/([^/]+)/g,
        to(route, productionId, userId, userEmail, invitationToken) {
            return `auth/handle-invite/production/${productionId}/invitation/${invitationToken}`;
        },
    },
    {
        from: /^productions\/\d+\/team\/accept-invite\/(\d+)\/(\d+)\/([^/]+)\/([^/]+)/g,
        to(route, productionId, userId, userEmail, invitationToken) {
            return `auth/handle-invite/production/${productionId}/invitation/${invitationToken}`;
        },
    },
    {
        from: /^productions\/\d+\/team\/accept-invite-not-activated\/(\d+)\/(\d+)\/([^/]+)\/([^/]+)/g,
        to(route, productionId, userId, userEmail, invitationToken) {
            return `auth/handle-invite/production/${productionId}/invitation/${invitationToken}`;
        },
    },
];
