/**
 * @file This is the entry point of the application.
 *
 * This file contains initialization code for some of the components.
 *
 * The most important stuff happening in this file is the setup of the routers,
 * the rendering of the main view, and the starting of Backbone.history.
 */

const Lime = require('lime-js');
const MainModel = require('core/main/main-model');

// A promise which is resolved once the registration scripts are loaded
const appsRegisterScripts = require('core/main/apps-register-scripts');
const MainView = require('core/main/main-view');
const BeforeUnload = require('before-unload-js');
const Trace = require('trace-js');
const StyleLoader = require('style-loader-js');
const routerConfig = require('core/main/router-config');
const session = require('session'); // initialize authentication
const UserModel = require('app-flow-core-js/src/datamodel/user/user-model');
const rightsCheck = require('core/util/rights-check');
const config = require('configuration');
const DebugTracer = require('debug-tracer-js');
const entryRouteRedirect = require('core/main/entry-route-redirect');
const routeMapping = require('core/main/route-mapping');
const CookieToToken = require('authentication-js/src/cookie-to-token');
const flowErrorHandler = require('core/main/flow-error-handler');
const RafSlicer = require('core/components/raf-slicer/raf-slicer');
const SerializationHelper = require('serialization-helper-js');

// Plugins
// added here because not included in authentication assemble
require('jquery.browser');

// "use strict";

let route;
const { _, Backbone, Log } = Lime;

// smooth the lodash 3 > 4 transition
_.any = _.some;
_.invoke = _.invokeMap || _.invoke; // note that this shadows the new _.invoke for object!

// expose some things for easier debugging and testing
window.Limecraft = window.Limecraft || {};

const traceIt = function traceIt(obj) {
    const extra = {
        launchTime: window.LAUNCH_TIME,
    };
    const productionId = Lime._.get(window, 'Limecraft.flow.attributes.production.id');
    if (productionId) {
        extra.productionId = productionId;
    }

    Trace.add(Lime._.extend(extra, obj));
};

// URL-based settings and flags
try {
    // disable analytics for uploader component
    // or if flag is passed to disable it (e.g. for
    // ui tests so no test users in freshchat)
    if (
        (window.location.href.indexOf('#uploader') > 0)
        || (window.location.href.indexOf('disableAnalytics') > 0)
    ) {
        config.analytics = {};
    }

    // Google cast demo
    if (window.location.href.toLowerCase().indexOf('demohdr') >= 0) {
        window.castAppId = 'B7769D29';
    }

    // Saml identityProvider. Used for NPO single sign on.
    if (window.location.href.indexOf('samlIDP=') > 0) {
        const regexp = /samlIDP=([^#?/]+)/g;
        const matches = regexp.exec(window.location.href);
        if (matches && matches.length === 2) {
            /* eslint-disable-next-line prefer-destructuring */
            Lime.samlIdentityProvider = matches[1];
        }
    }
} catch (ex) {
    Lime.Log.warn(ex);
}

// helper during dev
Lime.$._fakeAjax = function fakeAjax(options) {
    if (window.noFakeAjax || window.location.href.toLowerCase().indexOf('nofakeajax') >= 0) {
        return Lime.$.ajax(options);
    }
    Lime.Log.debug('fakeAjax %o', options);

    const dfd = Lime.$.Deferred();
    setTimeout(Lime._.bind(() => {
        if (options.failData) {
            dfd.rejectWith(null, options.failData);
        } else {
            dfd.resolveWith(null, options.responseData);
        }
    }, this), options.duration || 500);
    return dfd;
};

/**
 * This is the main model, which is the topmost model.
 * It will hold state for the web application for the entire
 * lifecycle of the web application.
 *
 * @memberOf Core.Main
 * @instance
 * @type {Core.Main.MainModel}
 */
const mainModel = new MainModel({
    // the name, which will be shown in header etc.
    name: config.headerText || 'Limecraft flow',

    // session
    session,

    // application configuration
    config,

    // application-level communication bus
    bus: Lime,
});

/**
 * This is the main view, which is the topmost view.
 * It will remain rendered for the entire lifecycle
 * of the web application. It has a
 * [setContent method]{@link Core.Main.MainView#setContent}
 * which is used to render something in the content area.
 *
 * @memberOf Core.Main
 * @instance
 * @type {Core.Main.MainView}
 */
const mainView = new MainView({
    model: mainModel,
});
mainView.render();
mainView.afterRender();

// configure the Alerter
Lime.Alerter.config({
    $el: mainView.$el,
    $loading: mainView.$el.find('#loading_message'),
    $alert: mainView.$el.find('.alert-container'),
    bootstrapVersion: 3,
});

/**
 * This is the top router, which is the topmost router in
 * the router hierarchy.
 *
 * @memberOf Core.Main
 * @instance
 * @type {TopRouter}
 */
const topRouter = new Lime.TopRouter(undefined, undefined, undefined, {
    type: Lime.TopRouter,
    subrouters: [],
    apps: [],
}, { mainView });

// This line accounts for this.options not being set any longer in Backbone 1.1.0.
//  The Backbone fix can be removed when all views that depend on this are changed
Backbone.View.prototype.initialize = function backboneViewInitializeFix(options) {
    this.options = _.clone(options);
};

// initialize Authentication layer
const lUrl = window.location.href;
session.init({
    UserClass: UserModel,
    LOGIN_URL: config.LOGIN_URL,
    LOGOUT_URL: config.LOGOUT_URL,
    REST_URL: config.REST_URL,

    // do not persist token in localStorage
    // - if this is a shared link
    // - if this is an edge login (so Flow logout won't invalidate Edge token afterwards)
    persist: (lUrl.indexOf('#shared/') < 0 && lUrl.indexOf('#auth/edge-auth') < 0 && lUrl.indexOf('nolocalstorage') < 0),
});

Lime._.defaults(window.Limecraft || {}, {
    session,
    Lime,
    configuration: config,
    unserializeOptions: SerializationHelper.unserializeOptions,
    serializeOptions: SerializationHelper.serializeOptions,
    mainModel,
    mainView
});

// bind cookie2token event which instructs
// us to load cookie as token
CookieToToken.on(Lime, session);

// set token when passed via url
(function setTokenWhenPassedViaUrl() {
    let matches; let
        token;
    try {
        matches = /token=([^&?#]*)/g.exec(window.location.search);
        if (matches && matches.length >= 2) {
            [, token] = matches;
            session.setToken(token);
        }
    } catch (ex) {
        Lime.Log.warn(`Setting token from url failed: ${ex}`);
    }
}());

/**
 * The application router of flow
 *
 * @memberOf Core.Main
 * @instance
 * @type {Core.Main.ApplicationRouter}
 */
const ApplicationRouter = routerConfig.type;
const router = new ApplicationRouter('', topRouter, undefined, routerConfig, {
    mainModel,
});
window.mainModel = mainModel;
// When an app is registered, we pass it the bus so the registration script can listen for events
Lime.on('apps:register', (appConfig) => {
    /* eslint-disable no-param-reassign */
    appConfig.bus = appConfig.bus || Lime;
    /* eslint-enable no-param-reassign */
});

// component to slice heavy work per animation frame to prevent stalling
RafSlicer.on(Lime);

// listen for requests to render something in the main view's content area
Lime.on('main:render', _.bind(function mainRenderEventHandler(...args) {
    try {
        mainView.setContent.apply(this, args);
    } catch (ex) {
        Log.error(ex);
    }
}, mainView));

// register the router with main router
topRouter.addSubRouter(router, 'mainRouter');

// Trigger the initial route and enable HTML5 History API support.

// wrap console so we can use console.log in bug reports
if (window.console) {
    Log.takeOverConsole();
}

const ajaxOptions = {
    // add url property to jqxhr, so it can be used in error logs
    beforeSend(jqxhr, settings) {
        /* eslint-disable no-param-reassign */
        jqxhr.url = settings.url + (settings.data ? (`?${settings.data}`) : '');
        /* eslint-enable no-param-reassign */
        jqxhr.setRequestHeader('Limecraft-API-Consumer', `Artlantic ${window.ARTLANTIC_VERSION}`);
        jqxhr.setRequestHeader('Limecraft-API-ClientSession', window.LAUNCH_TIME);
        // check if same origin, we mustn't add the Authorization header to
        // cross origin requests
        if (settings.url.indexOf(`${window.location.protocol}//${window.location.host}`) < 0) {
            return;
        }

        const token = session.getToken();
        if (!_.isEmpty(token)) {
            jqxhr.setRequestHeader('Authorization', (settings.headers && settings.headers.Authorization) || (`BasicToken ${token}`));
        }
    },
};
if (Lime.$.browser.msie) {
    ajaxOptions.cache = false; // fix for IE caching Ajax requests
}
const platform = (Lime.$.browser.platform || 'unknown').replace(/[^a-zA-Z]/g, '_');
Lime.$('body').addClass(`platform-${platform}`);
Lime.$.ajaxSetup(ajaxOptions);

// docker CORS experiment. Disabled in other environments for now because of NLB-1116
/*
if (window.location.host.indexOf("docker") >= 0) {
    Lime.$.ajaxSetup({
        //send cookies cross-domain
        xhrFields: {withCredentials: true}
    });
}
*/

// Log ajax errors
Lime.$(document).ajaxError((event, jqXHRArg, ajaxSettings) => {
    let url;
    const jqXHR = jqXHRArg || {};
    url = jqXHR.url || '';

    // remove any parameter that has a name starting with 'pass'
    url = url.replace(/(pass[^=]*)=([^&#]*)/g, '$1=HIDDENFROMLOG');

    if (jqXHR.status === 0 || jqXHR.status === '0' || jqXHR.statusText === 'abort') {
        return;
    }
    if ((jqXHR.status === 404 || jqXHR.status === '404') && url && url.indexOf('workflowreport/last?type=') >= 0) {
        return;
    }
    if (
        (jqXHR.status === 404 || jqXHR.status === '404')
        && jqXHR.responseText
        && jqXHR.responseText.indexOf('No MediaObjectWorkflowReport found for') >= 0
    ) {
        return;
    }


    Log.debug(`AJAXERROR: ${ajaxSettings.type || '???'} ${url || ''} resulted in [${jqXHR.status}] ${jqXHR.statusText} \n\n${jqXHR.responseText}`);
    traceIt({
        type: 'ajax-error',
        method: ajaxSettings.type,
        url,
        status: jqXHR.status,
        statusText: jqXHR.statusText,
        responseText: jqXHR.responseText,
        message: `AJAXERROR: ${ajaxSettings.type || '???'} ${url || ''} resulted in [${jqXHR.status}] ${jqXHR.statusText} \n\n${jqXHR.responseText}`,
    });
});

// Trace actions for error inspection periodically
if (window.location.href.indexOf('//localhost:') < 0) {
    DebugTracer.start({
        REST_URL: config.REST_URL,
        session,
    });
}

// set Lime.supportMailLink, which can be used in error messages to nudge the user towards
// contacting us
const { supportMail } = config;
Lime.supportMailLink = '';
if (supportMail !== 'NONE') {
    Lime.supportMailLink = Lime._.template(
        '<a class="SupportActivator" href="mailto:<%=supportMail%>" target="_blank"><%=supportMail%></a>'
    )({ supportMail });
}

/**
 * Generic fail handler
 * @private
 */
Lime.defaultFailHandler = flowErrorHandler;


// save error / warnings from log to debug trace
Log.on('all', (event, msg) => {
    try {
        if (['error', 'warning', 'info'].indexOf(event) < 0) {
            return;
        }

        const msgJson = _.clone(msg.attributes || {});
        if (msgJson.source instanceof Error) {
            msgJson.error_message = msgJson.source.message;
            msgJson.error_stack = msgJson.source.stack;
        } else if (['error', 'warning'].indexOf(event) >= 0) {
            msgJson.error_stack = new Error().stack;
        }

        // source is not needed
        delete msgJson.source;

        // Save warnings to the debug log
        traceIt(_.extend(msgJson, {
            type: `log-${event}`,
        }));
    } catch (ignore) {
        // ignore error
    }
});

if (!Log.get('ignoreConsoleMessages')) {
    Log.set('ignoreConsoleMessages', []);
}
// ignore the 3DSecure error for the dummy dialog. Their code does a console.error
// which is picked up here
Log.get('ignoreConsoleMessages').push({
    type: 'error',
    convertTo: 'warn',
    message: 'Base.DF: DFWeb reported a profile error'
});
Log.get('ignoreConsoleMessages').push({
    type: 'error',
    convertTo: 'warn',
    messageContains: 'Base.Main.GenericEvents: Failed to profile bin by event trigger'
});

// eslint-disable-next-line no-unused-vars
Log.on('error', (msgModel) => {
    // Show an indication to the user that something is going wrong
    if (config.mode !== 'DEVELOPMENT') {
        Lime.Alerter.showErrorMessage("We're sorry, but an unexpected error has occured.");
    } else {
        throw new Error(Lime._.get(msgModel, 'attributes.msg', Lime._.get(msgModel, 'message'), "We're sorry, but an unexpected error has occured."));
        //    Lime.Alerter.showErrorMessage(msg);
    }
});

// listen for uncaught stuff
window.addEventListener('error', (e) => {
    let msgJson;

    try {
        msgJson = {
            msg: e.message,
        };
        if (e.error && Lime._.isString(e.error)) {
            msgJson.error_message = e.error;
        } else if (e.error && e.error instanceof Error) {
            msgJson.error_message = e.error.message;
            msgJson.error_stack = e.error.stack;
        }

        msgJson.level = 3;
        msgJson.type = 'log-error';
        traceIt(msgJson);
    } catch (ignore) {
        // ignore exception
    }
});
window.addEventListener('unhandledrejection', (event) => {
    try {
        traceIt({
            level: 2,
            msg: `UnhandledPromiseRejection: ${event.reason || ''}`,
            type: 'log-warning',
        });
    } catch (ignore) {
        // ignore exception
    }
});

// Special Log.trigger('grouped', {}) event to reduce overhead on similar log events like workflow events
(function logGroupedWrapperFunction() {
    const groupedLogs = {};
    const flushGroup = function flushGroup(groupName) {
        if (!groupedLogs[groupName] || groupedLogs[groupName].length === 0) {
            return;
        }
        const firstMessage = groupedLogs[groupName];
        traceIt({
            type: 'log-group',
            msg: JSON.stringify({ group: groupName, msgs: groupedLogs[groupName].reverse() }, undefined, 2),
            productionId: Lime._.get(firstMessage, 'productionId'),
            mediaObjectId: Lime._.get(firstMessage, 'mediaObjectId'),
        });

        groupedLogs[groupName] = [];
    };
    Log.on('grouped', function logGroupedEventHandler(msg) {
        const theMsg = Lime._.extend({}, msg);
        groupedLogs[theMsg.group] = groupedLogs[theMsg.group] || [];
        theMsg.time = new Date();
        Lime.Log.debug(theMsg.msg);
        groupedLogs[theMsg.group].push(theMsg);
        if (groupedLogs[theMsg.group].length >= (theMsg.maxGroupLength || 10)) {
            flushGroup(theMsg.group);
        }
        if (groupedLogs[theMsg.group].length === 1) {
            setTimeout(Lime._.bind(flushGroup, this, theMsg.group), theMsg.maxTimeout || 5000); // timeout of 2 seconds to flush group
        }
    });
}());


traceIt({
    type: 'page-enter',
    codeHash: window.CODE_HASH,
    codeDate: window.CODE_DATE,
    codeVersion: window.CODE_VERSION,
    url: window.location.href,
    msg: `PAGE ENTER. It is now ISO ${new Date().toISOString()} / local ${new Date().toLocaleTimeString()}`,
    mode: config.mode,
    app: 'artlantic',
    navigator: (window.navigator && window.navigator.userAgent),
    windowSize: `${window.innerWidth}x${window.innerHeight}`,
    resolution: window.screen && (`${window.screen.width}x${window.screen.height}`),
    browserName: Lime._.get(Lime.$, 'browser.name', ''),
    browserPlatform: Lime._.get(Lime.$, 'browser.platform', ''),
    browserVersion: Lime._.get(Lime.$, 'browser.version', ''),
    browserVersionNumber: Lime._.get(Lime.$, 'browser.versionNumber', ''),
});

Lime.on('trace:time', (label, duration) => {
    let theDuration = duration;
    // the default duration is NOW - LAUNCH_TIME
    if (_.isUndefined(theDuration)) {
        theDuration = new Date().getTime() - window.LAUNCH_TIME;
    }
    traceIt({
        type: 'timing',
        label,
        duration: theDuration,
    });
    Log.debug('%s took %sms', label, theDuration);
});

Lime.on('trace:raw', (options) => {
    traceIt(options);
});

Lime.on('add:activity', (productionId, activity) => {
    Lime.Log.debug(activity);
    let url = `${config.REST_URL}activity`;
    if (productionId && Number(productionId) > 0) {
        url = `${config.REST_URL}production/${productionId}/activity`;
    } else {
        return; // not allowed for now to post to api/activity by regular user
    }
    Lime.$.ajax({
        method: 'POST',
        url,
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify([activity]),
        headers: {
            'Limecraft-API-Application': 'Flow.PostActivity',
        },
    });
});

// log each scheduled command as an activity
Lime.on('all', (eventName, command) => {
    try {
        if (eventName.indexOf('command:add') < 0) {
            return;
        }
        const commander = eventName.replace(':command:add', '');
        const item = Lime._.get(command, 'items[0]');
        const objectType = Lime._.get(item, 'attributes.objectType');

        // omit some commands (e.g. because they happen super often)
        if ([
            'SplitOnSpaces',
            'SaveTranscriptChanges',
            'SavePendingChanges',
            'SelectionClear',
            'ResetQuery',
            'MoveCursor',
        ].indexOf(command.type) >= 0) {
            return;
        }

        const logCommand = {};
        Lime._.each(command, (val, key) => {
            if ((typeof val).indexOf('string', 'number') >= 0) {
                logCommand[key] = val;
            }
        });

        if (objectType && item.id) {
            let itemId = item.id;
            if (Lime._.isString(itemId)) {
                itemId = Number(itemId.replace(`${objectType}_`, ''));
            }
            logCommand[Lime._.camelCase(`${objectType}Id`)] = itemId;
        }
        const productionId = Lime._.get(command, 'productionId')
            || Lime._.get(command, 'production.id')
            || Lime._.get(window.Limecraft, 'flow.attributes.selectedProductionId');

        const detailsMoId = Lime._.get(window, 'appModel.attributes.current.attributes.mediaObject.attributes.id');

        Lime.trigger('trace:raw', Lime._.extend(logCommand, {
            type: 'command',
            commandType: command.type,
            productionId,
            mediaObjectId: detailsMoId,
            userId: Lime._.get(session.me(), 'id'),
            commander,
            // command: logCommand,
        }));
    } catch (ex) {
        if (config.mode === 'DEVELOPMENT') {
            throw ex;
        } else {
            Lime.Log.warn(ex);
        }
    }
});

// enable console print out of Log when in development mode
if (config.mode === 'DEVELOPMENT') {
    Error.stackTraceLimit = 100;
    Log.set({ consolePrint: true });
}

Backbone.history.on('route', (routerInstance, routeStr, params) => {
    // Log route info on console
    Log.debug(...[
        `-- %s -> %s(${
            _.map(params || [], () => '%o').join(', ')
        }) --`,
        Backbone.history.fragment,
        routeStr,
    ]
        .concat(params));

    // Save routes to the debug log
    traceIt({
        type: 'route',
        route: Backbone.history.fragment,
    });
}); // bind on all route events

// Overwrite the default (empty) implementation of BasicView's rightsCheck
Lime.Views.AbstractView.prototype.rightsCheck = function abstractViewRightsCheckOverwrite() {
    rightsCheck(this.$el);
};

// listen for window's unload event and show user message when necessary
BeforeUnload.start();

if (config.mode === 'DEVELOPMENT') {
    import(/* webpackChunkName: "devtools" */ 'core/devtools')
        .then(_.bind((DevToolsNS) => {
            window.devtools = DevToolsNS.default;
            StyleLoader.loadCss('assets/css/development.css');
            if (window.location.host === 'localhost') {
                window.devtools.livedev();
            }
        }, this));
}

// Set up analytics
if (!Lime._.isEmpty(config.analytics)) {
    // we require analytics instead of adding it as a dependency, so it is loaded async,
    // because the library is quite large.
    import(/* webpackChunkName: "analytics-tracker" */ 'core/components/analytics-tracker/analytics-tracker').then(_.bind((AnalyticsTracker) => {
        const { analytics } = AnalyticsTracker;
        analytics.initialize(config.analytics);
        analytics.ready(() => {
            AnalyticsTracker.on(_.extend({ session, bus: Lime }, { analyticsConfig: config.analytics }));
        });
    }, this));
}

// make sure query params come before hash to avoid losing query params on Backbone.navigate
if (document.location.hash.indexOf('?') >= 0) {
    const hashParts = document.location.hash.split('?');
    Log.warn('swapping hash %s and query %s', hashParts[1], hashParts[0]);
    document.location = `${document.location.protocol}//${document.location.host}${document.location.pathname}?${hashParts[1]}${hashParts[0]}`;
}

// before starting Backbone, we check if we should map the entry route
route = document.location.hash.replace('#', '');
route = entryRouteRedirect(route);
document.location.hash = `#${route}`;

// certain (legacy) routes are redirected to other routes automatically
const originalLoadUrl = Lime.history.loadUrl;
Lime.history.loadUrl = function (fragment) {
    const theRoute = this.getFragment(fragment);
    let theTranslatedFragment = theRoute;

    Lime._.each(routeMapping, (map) => {
        if (theTranslatedFragment.match(map.from)) {
            theTranslatedFragment = map.to.apply(null, map.from.exec(theTranslatedFragment));
        }
    });

    if (theRoute !== theTranslatedFragment) {
        Lime.history.navigate(theTranslatedFragment, { trigger: true, replace: true });
        return false;
    }
    originalLoadUrl.call(Lime.history, theTranslatedFragment);
};

// Wait for the app registerScripts to be downloaded. This ensures the registerScript
// can run immediately without delay, which might be important to setup listeners in time.
appsRegisterScripts
// Lime.$.Deferred().resolve()
    .always(() => {
        try {
            // Actually start listening for routes
            Backbone.history.start();
            // Log the time until launch
            Lime.trigger('trace:time', 'backboneLaunch');
        } catch (e) {
            Log.error('starting Backbone history throws %o', e);
        }
    })
    .fail((...args) => {
        Lime.Log.error('Preloading registerScripts failed: %o', args);
    });
