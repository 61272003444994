const config = require('configuration');
const Lime = require('lime-js');

const wired = {};

/**
 * Methods for wiring apps
 *
 * @namespace AppWirer
 */
const AppWirer = /** @lends AppWirer */ {
    /**
     * Sets up listeners for the app routes, and will dynamically load the app code
     * when the route is called.
     *
     * @param {Lime.Router} parentRouter
     * @param {Array.<AppWirerApp>} apps
     */
    initializeApps(parentRouter, apps) {
        Lime._.each(apps, (r) => {
            let routeString;

            const appConfig = config.apps[r.name];
            const routes = {};

            routeString = (parentRouter.prefix || '') + appConfig.baseRoute;

            // remove trailing /
            routeString = (routeString[routeString.length - 1] === '/') ? routeString.slice(0, routeString.length - 1) : routeString;

            routes[routeString] = 'theRoute';
            routes[`${routeString}/*splat`] = 'theRoute';

            /* eslint-disable no-param-reassign */
            r.placeholderRouter = new (Lime.Backbone.Router.extend({
                routes,
                theRoute() {
                    // dynamic load router
                    r.importApp()
                        .then((AppRouterNS) => {
                            /** wire the app router */
                            AppWirer.wireRouter(AppRouterNS.default, parentRouter, r.name);
                        });
                },
            }))();
            /* eslint-enable no-param-reassign */
        });
    },

    /**
     * Once the App's Router code is loaded, create a router instance
     * and redirect the current route
     *
     * @param {Lime.Router} AppRouter - Router class of the app
     * @param {Lime.Router} parentRouter - instance of the parent router on which to add this app
     * @param {String} appName - Should point to an entry in apps of configuration.json
     */
    wireRouter(AppRouter, parentRouter, appName) {
        const appConfig = config.apps[appName];
        const key = appName + parentRouter.prefix + appConfig.baseRoute;
        const frag = Lime.history.fragment;
        if (wired[key]) {
            Lime.Log.error('App %s should implement route %s!', appName, frag);
            return;
        }

        Lime.Log.log(`Wiring app ${key}`);
        const appRouter = new AppRouter(
            parentRouter.prefix + appConfig.baseRoute,
            parentRouter,
            parentRouter[appConfig.inject],
            appConfig,
        );
        parentRouter.addSubRouter(appRouter, `routerApp${appName}`);
        wired[key] = true;

        Lime.Log.debug(`${appName} wired, reloading route ${frag}`);
        Lime.history.navigate('#dummy', { trigger: false });
        Lime.history.navigate(frag, { trigger: true, replace: true });
    },
};

/**
 * Contains information on a single word
 *
 * @typedef {Object} AppWirerApp
 * @property {String} name - Name of the app. Should correspond to the key in apps of configuration.json
 * @property {Function} importApp - A function to load the code. Should return a Promise which resolves with the Router class
 */

module.exports = AppWirer;
