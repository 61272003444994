const Backbone = require('better-backbone-js');

/**
 * @constructor MainModel
 * @memberOf Core.Main
 * @extends external:"Backbone.Model"
 *
 * @param {Object} [attributes] - Initialization parameters
 * @param {Object} [options]
 *
 * @classdesc This model holds state for the web application.
 */
const MainModel = Backbone.Model
    .extend({});
module.exports = MainModel;
