const jsonConfig = require('configuration.json');

/*
Configuration of some URL's etc.
*/

const config = window.forceConfig || jsonConfig;
const restContext = 'api';

// auto-init some things when not explicitly set
if (!config.provisioned) {
    config.myBackend = `${window.location.protocol}//${window.location.host}/`;
    config.restContext = restContext;
    config.REST_URL = `${config.myBackend + restContext}/`;
    config.LOGIN_URL = `${config.REST_URL}login`;
    config.LOGOUT_URL = `${config.REST_URL}logout`;

    if (window.location.host.indexOf('.limecraft.com') >= 0) {
        config.cometURL = `${window.location.protocol}//ws.${window.location.host}/cometd`;
    } else {
        config.cometURL = `${config.myBackend}cometd`;
    }

    // will be true if the client is running in brackets-shell
    config.standalone = (window.appshell && (window.location.protocol.indexOf('file') === 0));
}
if (config.supportMail === undefined) {
    config.supportMail = 'support@limecraft.com';
}

if (window.location.host.indexOf('platform.limecraft.com') >= 0) {
    config.mode = config.mode || 'PRODUCTION';
    config.envLabel = config.envLabel || 'lc';
} else if (window.location.host.indexOf('staging.limecraft.com') >= 0) {
    config.mode = config.mode || 'STAGING';
    config.envLabel = config.envLabel || 'lc-stag';
} else if (window.location.host.indexOf('limecraft.hotelhungaria.be') >= 0) {
    config.mode = config.mode || 'PRODUCTION';
    config.envLabel = config.envLabel || 'hh';
} else if (window.location.host.indexOf('flow.dock10.co.uk') >= 0) {
    config.mode = config.mode || 'PRODUCTION';
    config.envLabel = config.envLabel || 'd10';
} else {
    config.mode = config.mode || 'DEVELOPMENT';
    config.envLabel = config.envLabel || 'dev';
}

if (config.analytics && config.analytics.Freshdesk && config.analytics.Freshdesk.token === 'None') {
    delete config.analytics.Freshdesk;
}
if (window.location.href.indexOf('/beta/') > 0) {
    config.headerText = config.headerText || (`BETA ${(new Date(window.CODE_DATE)).toLocaleDateString()}`);
} else if (window.location.href.indexOf('localhost') > 0) {
    config.headerText = config.headerText || (`LOCAL ${(new Date(window.CODE_DATE)).toLocaleDateString()}`);
} else if (window.location.href.indexOf('development.limecraft.com') > 0) {
    config.headerText = config.headerText || (`DEV ${(new Date(window.CODE_DATE)).toLocaleDateString()}`);
}

// default environment settings
config.helpCenterLink = config.helpCenterLink || 'http://www.limecraft.com/help-center/';
config.termsAndConditionsLink = config.termsAndConditionsLink || 'http://www.limecraft.com/terms-of-service/';
config.privacyStatementLink = config.privacyStatementLink || 'http://www.limecraft.com/privacy/';
config.brandingWebsiteLink = config.brandingWebsiteLink || 'https://www.limecraft.com/';
config.shareFooterCreatedUsingText = config.shareFooterCreatedUsingText || 'Created using Limecraft Flow';
config.shareHeaderLogoImageUrl = config.shareHeaderLogoImageUrl || 'assets/css/img/artlantic-header-logo.png';

module.exports = config;
