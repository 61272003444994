/**
 * @file contains regular expressions for all routes
 * that are 'safe' to return to.
 *
 * A route that isn't safe to return to would be something like
 * .../delete/andThen/...
 */
module.exports = [
    /^invite-accept\/.*/g,
    /^shared\/.*/g,
    /^uploader/g,
    /^uploader\/.*/g,

    // admin section
    /^admin$/g,

    // edge download page
    /^edge-download$/g,

    // edge login
    /^edge-auth\/.*/g,
    // external login
    /^external-auth\/.*/g,

    // account overview
    /^accounts$/g,
    /^accounts\/\d+\/ws\/production.*/g,
    /^accounts\/\d+\/ws\/delivery.*/g,
    /^accounts\/\d+\/annotations.*/g,

    // production overview
    /^productions$/g,
    /^productions\/accounts\/.*/g,
    /^productions\/accounts\/\d+\/production-workspaces.*/g,
    /^productions\/accounts\/\d+\/delivery-workspaces.*/g,
    /^productions\/accounts/g,
    /^productions\/search\/.*/g,
    /^annotations.*$/g,
    /^productions\/\d+$/g,
    /^productions\/\d+\/$/g,
    /^productions\/\d+\/overview$/g,

    // production edit
    /^productions\/\d+\/edit$/g,

    // production settings
    /^productions\/\d+\/settings.*$/g,

    // production custom fields edit
    /^productions\/\d+\/edit\/customfields$/g,

    // production library config
    /^productions\/\d+\/edit\/library$/g,

    // production library config
    /^productions\/\d+\/workflows$/g,
    /^productions\/\d+\/mais$/g,
    /^productions\/\d+\/share$/g,

    // production admin
    /^productions\/\d+\/admin$/g,

    // team overview
    /^productions\/\d+\/team\/overview$/g,

    // teammember edit
    /^productions\/\d+\/team\/\d+\/edit$/g,

    // transfer overview
    /^productions\/\d+\/transfer\/overview$/g,

    // script editor
    /^productions\/\d+\/episodes\/\d+\/script$/g,

    // breakdown editor
    /^productions\/\d+\/episodes\/\d+\/breakdown$/g,

    // material
    /^productions\/\d+\/material$/g,
    /^productions\/\d+\/material\//g,

    // request overview
    /^productions\/\d+\/request$/g,
    /^productions\/\d+\/request\//g,

    // transcriber
    /^productions\/\d+\/mediaobjects\/\d+\/transcriber$/g,
    /^productions\/\d+\/mediaobjects\/\d+\/transcriber\/.*$/g,

    // lightbox on material overview
    /^productions\/\d+\/mediaobjects\/\d+\/lightbox\/andThen\/productions\/\d+\/material$/g,

    // assembler
    /^productions\/\d+\/material\/story$/g,
    /^productions\/\d+\/material\/story\/.*$/g,

    // last edl
    /^productions\/\d+\/episodes\/\d+\/edl\/lastEdl$/g,
    /^productions\/\d+\/episodes\/\d+\/edl\/\d+\/sync$/g,

    // logger
    /^productions\/\d+\/material\/logger/g,

    // Avid Sync
    /^productions\/\d+\/material\/avid-sync/g,

    // Details Screen
    /^productions\/\d+\/details-screen/g,
    /^productions\/\d+\/material\/details-screen/g,

    // Timeline Details
    /^productions\/\d+\/timeline-details/g,

    // Transcript Screen
    /^productions\/\d+\/material\/transcript/g,

    // segmenter
    /^productions\/\d+\/mediaobjects\/\d+\/segmenter$/g,
    /^productions\/\d+\/mediaobjects\/\d+\/segmenter\/([a-z|A-Z]+)$/g,
    /^productions\/\d+\/mediaobjects\/\d+\/segmenter\/([a-z|A-Z]+)\/zoom\/[\d|.]+\/scroll\/[\d|.]+$/g,
    /^productions\/\d+\/mediaobjects\/\d+\/segmenter\/([a-z|A-Z]+)\/t1\/[\d|.]+\/t2\/[\d|.]+$/g,

    /^users\/profile$/g,
    /^users\/\d+\/account$/g,
    /^users\/\d+$/g,
    /^users\/subscription$/g,
    /^users\/subscription-chargify\/.+$/g,

    // account settings
    /^account\/\d+\//g,

    /^subscription\/.*$/g,

    // admin
    /^admin\/.*$/g
];
