const dollar = require('the-real-jquery');
const Lime = require("lime-js");

/**
 * We use this file in place of the actual jquery dependency so we can expose jQuery to window. This is needed
 * by some dependencies, namely Backbone.
 */
window.jQuery = dollar;

window.$ = dollar; // for flow-ui-test sake

module.exports = dollar;
