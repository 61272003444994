/* eslint-disable no-unused-vars */
const Lime = require('lime-js');
const config = require('configuration');

/**
 * An array of possible error handlers. Each handler is a function which checks if it can handle the
 * error and does so if it can. If it handled the error, it should return true, to prevent other handlers
 * from being called.
 * The handlers are tried in order, so the first handler has the highest priority and the last handler
 * in the array has the lowest priority.
 *
 * @type {Array.<function>}
 */
const customHandlers = [];

/**
 * ignore aborts and cancels
 */
customHandlers.push((msg, textStatus) => {
    if (msg === 'close deferred calling object' || textStatus === 'abort' || msg === 'cancel') {
        return true;
    }
    return undefined;
});

customHandlers.push((jqXHR) => {
    if (
        Lime._.get(jqXHR, 'status') === 422
        && Lime._.get(jqXHR, 'responseJSON.type') === 'ValidationError'
        && Lime._.get(jqXHR, 'responseJSON.data[0].message')
    ) {
        Lime.Alerter.showErrorMessage(Lime._.get(jqXHR, 'responseJSON.data[0].message'));
        return true; // indicates error handled
    }
    return undefined;
});

/**
 * Transcript quota exceeded. Change the message based on whether speech recognition is enabled in the first
 * place on the subscription.
 */
customHandlers.push((jqXHR, textStatus, errorThrown) => {
    if (
        jqXHR
        && jqXHR.status === 402
        && jqXHR.responseJSON
        && jqXHR.responseJSON.type === 'QuotaExceededError'
        && jqXHR.responseJSON.billingUsage
        && jqXHR.responseJSON.billingUsage.billingType === 'TRANSCRIPTION'
    ) {
        if (jqXHR.responseJSON.billingUsage.maximumUnits <= 0) {
            let msg = 'Your subscription does not include automatic transcription.';
            if (Lime.supportMailLink) {
                msg += ` Please contact ${Lime.supportMailLink} to enable automatic transcription on your subscription.`;
            }
            Lime.Alerter.showErrorMessage(msg);
        } else {
            let msg = 'You exceeded the automatic transcription quota on your subscription.';
            if (Lime.supportMailLink) {
                msg += ` Please contact ${Lime.supportMailLink} to increase your quota.`;
            }
            Lime.Alerter.showErrorMessage(msg);
        }

        // return true to indicate the error has been handled
        return true;
    }
    return undefined;
});

/**
 * Error handler for a failed bulk action
 */
customHandlers.push((jqXHR, textStatus, errorThrown) => {
    let error;


    let alerterError;
    if (
        jqXHR
        && jqXHR.responseJSON
        && jqXHR.responseJSON.failedActions
        && jqXHR.responseJSON.failedActions.length
        && jqXHR.responseJSON.failedActions[0].errorResponse
    ) {
        error = Lime.ErrorHelper.makeErrorArguments.apply(Lime.ErrorHelper, [jqXHR.responseJSON.failedActions[0].errorResponse]);
        if (error && error.message && Lime._.isArray(error.message)) {
            // handle alternative server error format where message is an array (ValidationError uses this)
            error = Object.assign(error, error.message[0]);
        }
        // prevent makeErrorArguments from using jqXHR in favour of message
        if (error.message && Lime._.isString(error.message) && !Lime._.isEmpty(error.message)) {
            error.jqXHR = undefined;
        }
        alerterError = Lime.ErrorHelper.makeErrorArguments(error);
        Lime.Alerter.showErrorMessage(alerterError, alerterError.alerterTimeout);

        // return true to indicate the error has been handled
        return true;
    }
    return undefined;
});

/**
 * Error handler for a failed billing call (over bus)
 */
customHandlers.push((jqXHR, textStatus, errorThrown) => {
    let error;

    let alerterError;
    if (
        Lime._.get(jqXHR, 'responseJSON.error.errors[0]')
    ) {
        error = Lime.ErrorHelper.makeErrorArguments.apply(Lime.ErrorHelper, [Lime._.get(jqXHR, 'responseJSON.error.errors[0]')]);
        if (error && error.message && Lime._.isArray(error.message)) {
            // handle alternative server error format where message is an array (ValidationError uses this)
            error = Object.assign(error, error.message[0]);
        }
        // prevent makeErrorArguments from using jqXHR in favour of message
        if (error.message && Lime._.isString(error.message) && !Lime._.isEmpty(error.message)) {
            error.jqXHR = undefined;
        }
        alerterError = Lime.ErrorHelper.makeErrorArguments(error);
        Lime.Alerter.showErrorMessage(alerterError, alerterError.alerterTimeout);

        // return true to indicate the error has been handled
        return true;
    }
    return undefined;
});

/**
 * Failed event add returns an array of errors
 */
customHandlers.push((jqXHR, textStatus, errorThrown) => {
    let error;

    let alerterError;
    if (
        Lime._.isArray(Lime._.get(jqXHR, 'responseJSON'))
    ) {
        error = Lime.ErrorHelper.makeErrorArguments.apply(Lime.ErrorHelper, [Lime._.get(jqXHR, 'responseJSON[0]')]);
        if (error && error.message && Lime._.isArray(error.message)) {
            // handle alternative server error format where message is an array (ValidationError uses this)
            error = Object.assign(error, error.message[0]);
        }
        // prevent makeErrorArguments from using jqXHR in favour of message
        if (error.message && Lime._.isString(error.message) && !Lime._.isEmpty(error.message)) {
            error.jqXHR = undefined;
        }
        alerterError = Lime.ErrorHelper.makeErrorArguments(error);
        Lime.Alerter.showErrorMessage(alerterError, alerterError.alerterTimeout);

        // return true to indicate the error has been handled
        return true;
    }
    return undefined;
});

/**
 * Fallback error handler using ErrorHelper
 */
customHandlers.push((...args) => {
    let error;

    error = Lime.ErrorHelper.makeErrorArguments(...args);
    if (error && error.message && Lime._.isArray(error.message)) {
        // handle alternative server error format where message is an array (ValidationError uses this)
        error = Object.assign(error, error.message[0]);
    }
    // prevent makeErrorArguments from using jqXHR in favour of message
    if (error.message && Lime._.isString(error.message) && !Lime._.isEmpty(error.message)) {
        error.jqXHR = undefined;
    }
    const alerterError = Lime.ErrorHelper.makeErrorArguments(error);
    Lime.Alerter.showErrorMessage(alerterError, alerterError.alerterTimeout);
    return true;
});

module.exports = function flowErrorHandler(...args) {
    // execute error handlers until one returns true

    Lime._.each(customHandlers, (handler) => {
        if (handler(...args) === true) {
            return false;
        }
        return undefined;
    });
};
