const Lime = require('lime-js');
const config = require('configuration');
const AppWirer = require('app-wirer');
const session = require('session');
const entryRouteRedirect = require('./entry-route-redirect');

// Defining the application router.

/**
 * @memberOf Core.Main
 * @constructor ApplicationRouter
 * @extends Lime.Router
 * @classdesc This is the application router for flow.
 */
const Router = Lime.Router.extend(
    /** @lends Core.Main.ApplicationRouter.prototype */
    {
        routes: {
            'error/*': 'error',
            '': 'defaultRoute',
            'refresh/andThen/*path': 'refreshAndThen',
            'recheck/andThen/*path': 'recheckSessionRoute',
        },
        noLoginRequiredFor: ['refreshAndThen'],

        /**
         * Initialization: initialize a few subrouters and register the Flow app
         * @param {String} prefix
         * @param {Lime.Router} parent
         * @param {function} prepareInjection
         * @param {Object} routerConfig
         * @param {Object} otherOptions
         */
        initialize(prefix, parent, prepareInjection, routerConfig, otherOptions) {
            this._super('initialize', [prefix, parent, prepareInjection, routerConfig, otherOptions]);
            this.options = otherOptions;

            AppWirer.initializeApps(this, [
                {
                    name: 'Authentication',
                    importApp() {
                        return import(
                            /* webpackChunkName: "auth-app" */ 'app-flow-auth-js/src/authentication-router'
                        );
                    },
                },
                {
                    name: 'ShareViewer',
                    importApp() {
                        return import(
                            /* webpackChunkName: "share-viewer-app" */ 'app-flow-share-viewer-js/src/app-share-viewer-router'
                        );
                    },
                },
                {
                    name: 'Uploader',
                    importApp() {
                        return import(
                            /* webpackChunkName: "uploader-app" */ 'app-flow-uploader-js/src/uploader-app-router'
                        );
                    },
                },
            ]);

            parent.on('unknown', this.loadFlowAndThen, this);
        },

        /**
         * The default route
         */
        defaultRoute() {
            Lime.history.navigate('last', { trigger: true });
        },

        /**
         * Load the Flow code, and then navigate to the route
         * @param afterwardsRoute   The route to navigate to once Flow has loaded
         */
        loadFlowAndThen(afterwardsRoute) {
            if (this._flowWired) {
                // This route is invalid, so navigate to "", which will trigger the defaultRoute
                Lime.Alerter.showErrorMessage(`Invalid route ${Lime.history.fragment}`);
                Lime.Log.warn('Got invalid route %s', Lime.history.fragment);
                Lime.history.navigate('', { trigger: true });

                return undefined;
            }
            return import(/* webpackChunkName: "flow-core-app" */ 'app-flow-core-js/src/flow-router')
                .then(Lime._.bind(function flowLoadedThenCallback(AppRouterNS) {
                    Lime.history.navigate(`#${afterwardsRoute}`, {
                        trigger: false,
                        replace: true,
                    });
                    AppWirer.wireRouter(AppRouterNS.default, this, 'Flow');
                    this._flowWired = true;
                }, this));
        },

        /**
         *
         */
        checkLoggedIn(successCallback) {
            /**
             * We wrap successCallback with a try..catch so everything thrown that is not catched
             * will be catched here.
             */
            try {
                if (successCallback) {
                    successCallback();
                }
            } catch (e) { // catch all errors
                Lime.Log.error(e);
                if (config.mode === 'DEVELOPMENT') {
                    throw e; // throw it again. This should only be done in development mode
                }
            }
        },

        /**
         * A route which just redirects
         */
        refreshAndThen(splat) {
            Lime.history.navigate(splat, { trigger: true });
        },

        injectConfig() {
            return this.Deferred()
                .resolve(Lime.$.extend(this.injection || {}, { config }))
                .promise();
        },
        unknownRoute() {
            Lime.Log.warn('unknown route %s', Lime.history.fragment);
            this.trigger('unknown', Lime.history.fragment);
        },
        beforeEveryRouteBubbling(...args) {
            this.parent.beforeEveryRouteBubbling(...args);
        },

        /**
         * This route forces reloading user / account details
         * @param {string} afterwards - redirect to this route when logged in and reload of details
         */
        recheckSessionRoute(afterwards) {
            session.checkLoggedIn(true)
                .done(() => {
                    Lime.history.navigate(afterwards, { trigger: true });
                });
        },

        /**
         * Does some preparation for routes within Flow:
         *  1/ The user has to be logged in
         *  2/ The dashboard is bootstrapped
         *  3/ The productions are loaded
         * Some useful variables like config, dashboard etc. are passed via injection.
         *
         * @returns {Lime.$.Deferred} Deferred which resolves to injection to be used in route methods
         * @private
         */
        __prepareDash(force) {
            const { injection } = this;
            const dfd = Lime.$.Deferred();

            /**
             * The user has to be logged in
             */
            session.checkLoggedIn(force)
                .then(
                    /**
                     * Bootstrap the dashboard.
                     *
                     * Note that we are calling a method of this.routerAppFlow, which is
                     * the app router of Flow app. It should be available at this point
                     * as this method is used as an injection for that router.
                     */
                    Lime._.bind(this.routerAppFlow.__bootstrapFlow, this.routerAppFlow),

                    /**
                     * On fail, redirect to login
                     */
                    (jqXHR) => {
                        if (jqXHR && (jqXHR.status === 503 || jqXHR.status === 502)) {
                            Lime.Alerter.showErrorMessage('The server seems to be unavailable at the moment. Please try again in a minute.');
                        }

                        const currentFragment = Lime.Backbone.history.fragment;
                        let route = '#auth/login';
                        if (!Lime._.isEmpty(currentFragment)) {
                            route = `#auth/login/andThen/${Lime.Backbone.history.fragment}`;
                        }
                        Lime.history.navigate(route, { trigger: true });
                        return 'close deferred calling object'; // mimick user cancel to avoid injectionError being called in child routers
                    },
                )

                /**
                 * Inject useful vars
                 */
                .then(Lime._.bind(function prepareDashInjectUsefulVars(dashboard) {
                    return Lime._.extend({
                        mainBus: this.options.mainModel.get('bus'),
                        config,
                        session,
                        dashboard,
                        entryRouteRedirect,
                    }, this.options, injection);
                }, this))
                .then(dfd.resolve, dfd.reject);
            return dfd.promise();
        },

        /**
         * Pass session via injection
         * @returns {*}
         * @private
         */
        __injectSession() {
            return Lime.$.Deferred()
                .resolve(Lime._.extend({}, this.injection, {
                    session,
                    config,
                    mainBus: this.options.mainModel.get('bus'),
                }, this.options));
        },
    },
);
module.exports = Router;
