const config = require('configuration');
const Lime = require('lime-js');
const materialRegister = require('app-flow-material-js/src/registration/register');

const subscriptionManagerRegister = require('app-flow-subscription-manager-js/src/registration/register');
const assemblerRegister = require('app-flow-assembler-js/src/registration/register-assembler');
const avidSyncRegister = require('app-flow-avid-sync-js/src/registration/avid-sync-register');
const detailsRegister = require('app-flow-details-screen-js/src/registration/details-screen-register');
const uploadRegister = require('app-flow-uploader-js/src/registration/uploader-app-register');
const transcriptRegister = require('app-flow-transcript-js/src/registration/transcript-register');

Object.entries({
    Material: materialRegister,
    SubscriptionManager: subscriptionManagerRegister,
    Story: assemblerRegister,
    AvidSync: avidSyncRegister,
    DetailsScreen: detailsRegister,
    Uploader: uploadRegister,
    Transcript: transcriptRegister,
}).forEach((keyValuePair) => {
    const [key, registerFn] = keyValuePair;
    const appConfig = Object.assign({
        my: {},
        bus: Lime,
    }, config.apps[key]);

    appConfig.prefix = ((appConfig.parentRouter || appConfig.parentApp || {}).prefix || '') + appConfig.baseRoute;

    registerFn(appConfig);
});

module.exports = Lime.$.Deferred()
    .resolve();
