const $ = require('jquery');
const _ = require('lodash');
const session = require('session');

/*
 Hide elements that should only be shown for users with certain rights
 */
const checkRights = function checkRights(viewEl) {
    const restrictedElements = viewEl.find('[data-required-rights]').toArray();

    if (viewEl.attr('data-required-rights')) {
        restrictedElements.push(viewEl[0]);
    }

    if (restrictedElements.length === 0) {
        return;
    }
    for (let i = 0; i < restrictedElements.length; i += 1) {
        const requiredRightsString = restrictedElements[i].getAttribute('data-required-rights');
        const hasRights = session.me().checkRights(requiredRightsString);
        if (hasRights) {
            $(restrictedElements[i])
                .removeClass('restricted-nok')
                .addClass('restricted-ok');
        } else {
            $(restrictedElements[i])
                .removeClass('restricted-ok')
                .addClass('restricted-nok');
        }
    }
};
module.exports = checkRights;
